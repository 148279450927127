import React from "react";
import { Link } from 'react-router-dom';
import './morgan-stanley.scss';
import MorganStanley from "../../assets/images/morgan-stanley/morgan-stanley-cover.png"
import DesignSystem from "../../assets/images/morgan-stanley/msatwork design system.png"
import Rebel from "../../assets/images/morgan-stanley/ms-rebel.png"
import Dashboard from "../../assets/images/morgan-stanley/ms-dashboard.png"
import IA from "../../assets/images/morgan-stanley/ms-ia.png"
import Metrics from "../../assets/images/morgan-stanley/ms-metrics.png"
import Fund from "../../assets/images/morgan-stanley/ms-fund.png"
import Sketches from "../../assets/images/morgan-stanley/ms-sketches.png"
import Specs from "../../assets/images/morgan-stanley/ms-specs.png"
import Samana from "../../assets/images/samana/samana-cover.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const MorganStanleyProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Retirement plan sponsor experience by Morgan Stanley</h1>
          <h5>UX designer / Jan 2021 - Mar 2022</h5>
        </div>
        </div>
          <img className="img" src={MorganStanley} alt="Retirement plan sponsor experience project cover" />
          <div id="paragraph">
            <p>I was the lead designer on the new <Link className="link" to="https://www.morganstanley.com/press-releases/morgan-stanley-at-work-participant-aggregation-platform" target='_blank'>retirement plan sponsor experience</Link> offered through Morgan Stanley at Work. This platform helps plan sponsors track the overall health of their organizations retirement plans, and have improved communication with their financial advisors.</p>
          </div>
        </div>

      <div id="challenge">
        <div>
          <h3 style={{padding: "12px 0"}}>Challenge</h3>
        </div>
        <div className="even-grid">
          <p>I was introduced to the project in January 2021. Morgan Stanley had previously hired an agency <Link className="link" to="https://www.rebel-co.com/" target='_blank'>Rebel & Co</Link> to design a north star vision of the retirement plan sponsor experience.</p>
          <img className="img" src={Rebel}/>
          <div className="text-paragraph">
            <p>Since these designs were north star oriented, it did not integrate well with the current backend and capabilities that Morgan Stanley at Work offered. Morgan Stanley also wanted this product to be white labeled so that each client can add their own branding. I was brought in to this project to design the MVP for an initial public launch, and also use this project as a case study to build the new Morgan Stanley at Work design system.</p>
            <p>My design touched on two key areas of improvement:</p>
          </div>
        </div>
      </div>

      <div id="creating deliverables">
        <div>
          <h3 style={{padding: "12px 0"}}>Creating deliverables for MVP</h3>
          <div className="even-grid">
          <div className="text-paragraph">
            <p> I began by learning about the user, their needs, and the retirement space. I reviewed research interviews conducted by Rebel & Co to understand the plan sponsors role and how they administer a company’s retirement plan. I also conducted interviews with Morgan Stanley financial advisors to understand which retirement metrics are most commonly brought up in their conversations with plan sponsors. This also led me to learn about the corporate retirement portal (CRP) which is the financial advisor facing platform, and reports sent by financial advisors to their clients.</p>
            <p>After the research I gathered the findings, I worked with my product manager and engineering team to narrow down the key metrics to surface for the MVP experience.</p>       
          </div>
          <img className="img" src={IA}/>
          <img className="img" src={Metrics}/>
          <div className="column-grid">
            <img className="img" src={Dashboard}/>
            <img className="img" src={Fund}/>
          </div>
          </div>
        </div>
      </div>

      <div id="designed features">
        <div>
          <h3 style={{padding: "12px 0"}}>Designed features</h3>
        </div>
        <div className="even-grid">
          <p>In addition to the metrics, I worked on designing the rest of the product experience.</p>
          <img className="img" src={Sketches} style={{borderRadius: "0"}}/>
          <img className="img" src={Specs} style={{borderRadius: "0"}}/>
        </div>
      </div>

      <div id="feature 1">
        <div>
          <b>1. Onboarding experience</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>The onboarding experience helps to collect the relevant information necessary for a plan sponsor to set up their company's account. This is also beneficial for financial advisors to have a better idea of the goals of the retirement plan.</p>      
          </div>
          <div style={{padding:"71.2% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927261092?h=d6d142f3f2&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 2">
        <div>
          <b>2. Dashboard</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>The dashboard gives an overview of the health and progress of the retirement plan. There are two views that plan sponsors can choose from:</p>
            <ul>
              <p>
                <li>
                  <span style={{fontWeight: "600"}}>Overview:</span> This shows metrics on the plan's performance and participant engagement.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Plan details:</span> Shows a breakdown of the funds and allocation.
                </li>
              </p>
            </ul>      
          </div>
          <div style={{padding:"71.2% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927313190?h=2bf995ab88&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 3">
        <div>
          <b>3. Participants</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>Shows all the participants contributing to the plan.</p>      
          </div>
          <div style={{padding:"71.2% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927316729?h=f3602ea7a5&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 4">
        <div>
          <b>4. File vault</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>A space to share documents for approval with your financial advisor and other plan administrators. Files are organized in terms of approved and unapproved.</p>      
          </div>
          <div style={{padding:"83.33% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927318969?h=bf19d7cf7b&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <div className="text-paragraph">
            <p>In addition to viewing the file, users can also rename, move, manage versions, and also see who the file has been shared with.</p>      
          </div>
          <div style={{padding:"83.33% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927320734?h=332c9b1297&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <div className="text-paragraph">
            <p>When files are uploaded, they need to be approved by the financial advisor before they can be shared.</p>      
          </div>
          <div style={{padding:"83.33% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927320765?h=8bc2f6ee43&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 5">
        <div>
          <b>5. Learn & support</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>Learning center with retirement related articles and FAQ.</p>      
          </div>
          <div style={{padding:"67.36% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927320707?h=948ecbc376&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 6">
        <div>
          <b>6. Account settings</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>Plan sponsors have the ability to edit information they added during onboarding.</p>      
          </div>
          <div style={{padding:"86.79% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927320778?h=6e99f4ba53&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="feature 7">
        <div>
          <b>7. Financial advisor contact</b>
          <div className="even-grid">
          <div className="text-paragraph">
            <p>Plan sponsors can find their financial advisor contact directly through the experience.</p>      
          </div>
          <div style={{padding:"71.2% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/927320688?h=c8498c7093&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>

      <div id="design system">
        <div>
          <h3 style={{padding: "12px 0"}}>Design system</h3>
          <div className="even-grid">
          <div className="text-paragraph">
          <p>When Shareworks by Morgan Stanley became rebranded as Morgan Stanley at Work, there was a push from design leadership to have one coherent design language in the product portfolio. Since the newest product in the portfolio was to be the retirement plan sponsor experience, I was tasked with the creation of the MSatWork design system as well as the migration of three legacy design systems from Sketch to a Figma.</p>
          <p>I led and managed a team of four designers that successfully migrated 80% of the of the legacy design systems before i left. My tasks in addition to the managing the migration involved conducting tutorials on new Figma features such as auto layout to make sure components were built consistently.</p>
          <p>The MSatWork design system became widely adopted by the design team, and this helped the asset library grow as components were built for specific product use cases. The MSatWork design system remains the main design system used for new Morgan Stanley at Work products. </p>
          </div>
          <img className="img" src={DesignSystem} alt="Loop as a tab" />
          </div>
        </div>
      </div>

      <div id="result">
        <div>
          <h3 style={{padding: "12px 0"}}>Result</h3>
          <div className="even-grid">
          <div className="tex-paragraph">
            <p>My time with Morgan Stanley came to an end before the experience could be shipped. The plan sponsor experience MVP <Link className="link" to="https://www.morganstanley.com/press-releases/morgan-stanley-at-work-participant-aggregation-platform" target='_blank'>shipped in September 2022</Link>, with majority of my work being implemented in the final product.</p> 
          </div>
          </div>
        </div>
      </div>


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={Samana} alt="Samana" />
            <div>
              <h6>Samana</h6>
              <Link className="explore" to="/samana">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default MorganStanleyProject