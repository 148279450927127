import React from "react";
import { Link } from 'react-router-dom';
import './go-studio-fx.scss';
import Challenge from "../../assets/images/gsfx/gsfx-challenge.png"
import GoStudioFX from "../../assets/images/gsfx/gsfx-cover-2.png"
import Samana from "../../assets/images/samana/samana-cover.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"
import BT from "../../assets/images/gsfx/gsfx-bt.png"
import Edit from "../../assets/images/gsfx/gsfx-edit.png"
import Record from "../../assets/images/gsfx/gsfx-record.png"
import Share from "../../assets/images/gsfx/gsfx-share.png"
import Process from "../../assets/images/gsfx/gsfx-process.png"
import Survey from "../../assets/images/gsfx/gsfx-survey.png"
import Persona1 from "../../assets/images/gsfx/gsfx-persona1.png"
import Persona2 from "../../assets/images/gsfx/gsfx-persona2.png"
import Old from "../../assets/images/gsfx/gsfx-old.png"
import Findings from "../../assets/images/gsfx/gsfx-findings.png"
import Takeaways from "../../assets/images/gsfx/gsfx-takeaways.png"
import Lowfi from "../../assets/images/gsfx/gsfx-lowfi.png"
import Hifi from "../../assets/images/gsfx/gsfx-hifi.png"
import Ia from "../../assets/images/gsfx/gsfx-ia.png"
import Style from "../../assets/images/gsfx/gsfx-style.png"
import Timeline from "../../assets/images/gsfx/gsfx-timeline.png"
import FX from "../../assets/images/gsfx/gsfx-fx.png"
import Insp1 from "../../assets/images/gsfx/gsfx-insp1.png"
import Insp2 from "../../assets/images/gsfx/gsfx-insp2.png"
import Insp3 from "../../assets/images/gsfx/gsfx-insp3.png"
import Orientation from "../../assets/images/gsfx/gsfx-orientation.png"
import Blender from "../../assets/images/blender/blender-cover.png"


const GoStudioFXProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Go Studio FX</h1>
          <h5>Product designer / Jul 2018 - Dec 2019</h5>
        </div>
        </div>
          <img className="img" src={GoStudioFX} alt="Loop Integration in Teams project cover" />
          <div id="paragraph" className="text-paragraph">
            <p>TC Helicon is known for making high quality pedals that enhance performers' voices with live audio effects. One of the issues faced by the company was to make the experience more accessible to people who are not sure if they should purchase a product.</p> 
            <p>Thus sparked the idea of Go Studio FX, where performers could have the first experience through the app for free, and then go on to buy the hardware product that suits their needs.</p>
            <p>I was the sole Product Designer for Go Studio FX. My responsibilities included concept development, user research, user interface design, interaction design, branding, and testing.</p>
            <p>The app was launched on the <Link className="link" to="https://apps.apple.com/ao/app/go-studio-fx/id1195139936" target='_blank'>iOS App Store</Link> on <span style={{fontWeight: "600"}}>Aug 5, 2020.</span></p>
          </div>
        </div>

      <div id="challenge" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Challenge</h3>
          <h4 style={{padding: "12px 0"}}>Design a product that makes the TC Helicon experience more accessible and increase brand awareness among younger users.</h4>
          <p>Previous research revealed that TC Helicon products struggle to grab the attention of young/new singer songwriters, because there was no way other than by purchasing a product to experience their vocal effects. This created a barrier for customers who were not sure which TC Helicon product/vocal effects were ideal for their performance.</p>
        </div>
        <img className="img" src={Challenge} alt="Challenge"/>
      </div>

      <div id="design solution" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Design solution</h3>
          <p>Go Studio FX is a free video recording and effect sequencing iOS mobile application. The app allows users to edit their performance using TC Helicon’s known vocal and video effects, and share it on their social media platform of choice.</p>
        </div>
        <iframe className="youtube-video" src="https://www.youtube.com/embed/h3jsYi8Kl1c" title="Go Studio FX Mobile App Launch" style={{border: "0"}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="key-features">
        <h3>Key Features</h3>

        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Record your performance</h6>
            <p>Whether its for YouTube or Instagram Stories, Go Studio FX lets you record in the orientation of your choosing.</p>
          </div>
          <img className="img" src={Record} alt="Challenge" />
        </div>

        <div id="feature 2" className="features-grid-even">
          <img className="img" src={BT} alt="Challenge" />
          <div className="features-grid-text-even">
            <h6>Add a backing track or instrument track</h6>
            <p>Take advantage of auto key detection when you add backing and instrument tracks to your performance.</p>
          </div>
        </div>

        <div id="feature 3" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Edit your performance with audio and video effect sequencing</h6>
            <p>Have total control on when you want to add an effect, and the duration of how long that effect should last.</p>
          </div>
          <img className="img" src={Edit} alt="Challenge" />
        </div>

        <div id="feature 4" className="features-grid-even">
          <img className="img" src={Share} alt="Challenge" />
          <div className="features-grid-text-even">
            <h6>Share your performance with your fans</h6>
            <p>Export locally to your device, or share directly to a social platform of your choosing.</p>
          </div>
        </div>
      </div>

      <div id="constraints and limitations" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Constraints and limitations</h3>
          <div className="text-paragraph">
            <p>Go Studio FX was not a perfect project, and it came with its share of constraints and limitations that affected the final outcome:</p>
            <ul>
              <p>
                <li>Limited budgets, stakeholders for research, testing, marketing plans.</li>
                <li>Lack/change of project management vastly changed the business goals and deadlines.</li>
                <li>Pairing Go Studio FX with another hardware product for launch caused delays.</li>
              </p>
            </ul>
          </div>
          <img className="img" src={Process} alt="roadmap" />
        </div>
      </div>

      <div id="target audience" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Target audience</h3>
          <p>The users of Go Studio FX are singer/songwriters who actively engage with their fans through content on social media.</p>
        </div>
        <div style={{maxWidth: "960px", margin: "0 auto"}}> 
        <img className="img" src={Survey} alt="survey results" style={{marginBottom: "40px", borderRadius: "0"}}/>
        <div id="ta-carousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#ta-carousel" data-slide-to="0" className="active"></li>
            <li data-target="#ta-carousel" data-slide-to="1"></li>
            <li data-target="#ta-carousel" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={Persona1} alt="First slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={Persona2} alt="Second slide"/>
            </div>
          </div>
          <a className="carousel-control-prev" href="#ta-carousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#ta-carousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        </div>
      </div>

      <div id="early concept">
        <div>
          <h3 style={{padding: "12px 0"}}>Early concept</h3>
          <p>To test the existing app, I conducted internal user interviews. I received a variety of feedback on opportunities, user facing problems and technical issues. The feedback was collected and categorized into findings and goals.</p>
          <div style={{maxWidth: "800px", margin: "40px auto"}}>
          <img className="img" src={Old} alt="old mocks of go studio fx"/>
          </div>
        </div>
      </div>
      <div className="column-grid">
        <div id="column 1" className="column">
          <div className="column-heading">
            <img src={Findings} style={{maxWidth: "200px"}}/>
            <h6 >Findings</h6>
          </div>
            <div>
              <b>Barriers to Perform</b>
              <p>Users were required to configure their project before they could start recording.</p>
            </div>
            <div>
              <b>Limited to Landscape Orientation</b>
              <p>The app was initially intended for YouTube so it only supported landscape orientation.</p>
            </div>
            <div>
              <b>Complex Interactions</b>
              <p>The existing interactions were complex and counter intuitive for new users.</p>
            </div>
        </div>
        <div id="column 2" className="column">
          <div className="column-heading">
            <img src={Takeaways} style={{maxWidth: "200px"}}/>
            <h6>Takeaways</h6>
          </div>
            <div>
              <b>Easily Record Video</b>
              <p>Structure the task flow in a way that encourages users to start creating.</p>
            </div>
            <div>
              <b>Support Portrait Orientation</b>
              <p>Empower our users to create videos for multiple platforms.</p>
            </div>
            <div>
              <b>Organized Features and Content</b>
              <p>Structure features in a way that encourages interaction, and easy entry and exit points.</p>
            </div>
        </div>
      </div>

      <div id="success metrics">
        <div>
          <h3 style={{padding: "12px 0"}}>Success metrics</h3>
          <p>Before I started working on the designs, I took a step back to discuss how we would measure success for Go Studio FX:</p>
          <ul>
            <p>
              <li>Conversion rates of social media influencers (without paid sponsorships).</li>
              <li>Measuring how many users unlock the app with a TC Helicon hardware product.</li>
              <li>Measuring the number of in-app purchases bought.</li>
              <li>Monitoring and measuring TC Helicon mobile product sales post launch..</li>
              <li>The amount of projects created vs. exported.</li>
              <li>Monitoring TC Helicon social media tags, mentions and content</li>
            </p>
          </ul>
        </div>
      </div>

      <div id="wireframes">
        <div>
          <h3 style={{padding: "12px 0"}}>Wireframes</h3>
          <img className="img" src={Lowfi} />
        </div>
      </div>

      <div id="inspiration">
        <div>
          <h3 style={{padding: "12px 0"}}>Inspiration</h3>
          <p>I drew inspiration from social media apps such as Instagram, Snapchat, and Tik Tok. I specifically studied layouts for record screens.</p>
        </div>
        <div id="insp-carousel" className="carousel slide" data-ride="carousel" style={{marginTop: "40px"}}>
          <ol className="carousel-indicators">
            <li data-target="#insp-carousel" data-slide-to="0" className="active"></li>
            <li data-target="#insp-carousel" data-slide-to="1"></li>
            <li data-target="#insp-carousel" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={Insp1} alt="First slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={Insp2} alt="Second slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={Insp3} alt="Third slide"/>
            </div>
          </div>
          <a className="carousel-control-prev" href="#insp-carousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#insp-carousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div className="even-grid">
        <h3 style={{padding: "12px 0"}}>Visual design</h3>
        <img className="img" src={Style} style={{maxWidth: "800px", borderRadius: "0"}}/>
        <img className="img" src={FX} style={{maxWidth: "800px", borderRadius: "0"}}/>
      </div>
      <div className="even-grid" id="solutions">
          <h3 style={{padding: "12px 0"}}>Solutions</h3>

        <div id="solution 1">
          <div className="even-grid">
            <h4>1. Empowering users to perform</h4>
            <div>
              <b>The old app required the user to select a backing track and or an instrument before the user could start recording.</b>
              <p>The new app has the track configurations in the record screen. This lets users go straight in to the record screen and start performing, and allows the user to change backing tracks and instrument tracks without having to go back to the projects screen.</p>       
            </div>
          </div>
          <img className="img" src={Ia} />
        </div>

        <div id="solution 2">
          <div className="even-grid">
            <h4>2. Supporting Multiple Orientations</h4>
            <div>
              <p>To provide an easy experience of shooting video in multiple orientations, the new record screen has a layout that can change based on the orientation.</p>       
            </div>
            <div style={{padding:"75% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/408141483?h=6f720c3ddc&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <p>The edit screen adapts the layout based on the orientation that the video was shot at.</p>
          <img className="img" src={Orientation} style={{maxWidth: "800px", margin: "0 auto"}}/>
          <div>
          <b>Preview Mode</b>
          <p>On portrait orientation, the content on the edit screen covers most of the video making it harder to see your edit. I designed a new preview button/motion that can collapse the FX grid to preview the video as if exported.</p>
          </div>
          <div style={{padding:"75% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/408206411?h=7a8aa677de&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>  
        </div>

        <div id="solution 3">
          <div className="even-grid">
            <h4>3. Clear Effect Interactions</h4>
            <div className="even-grid">
              <div>
              <b> Participants from the usability study did not know that they could tap an effect block on the timeline and stretch or drag it. Instead, they would only double tap to delete the effect.</b>
              <p>When I dove deeper and asked “Why?”, the feedback pointed towards a lack of visual indication.</p>
              </div>
              <div>
              <b>The solution</b>
              <p>Once a block is tapped, it is highlighted with visible handles. When an end point on the selected block reaches the end point of another block, the handle which aligns snaps and changes its colour to confirm.</p>
              </div>
            </div>
            <div className="column-grid">
              <img className="img" src={Timeline} style={{maxWidth: "800px", margin: "0 auto"}}/>
              <div style={{padding:"75% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/431669123?h=5cd586321f&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>  
        </div>
      </div>

      <div id="additional interactions">
        <div className="even-grid">
          <h3 style={{padding: "12px 0"}}>Additional interactions</h3>
          <div className="column-grid" style={{alignItems: "center"}}>
          <div style={{padding:"75% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/408225448?h=fe2e6a5403&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            <div>
              <b>Level Controls</b>
              <p>Backing track and instrument track pages now have level controls to give the user an alternative place from the mixer to adjust levels.</p>
            </div>
          </div>
          <div className="column-grid" style={{alignItems: "center"}}>
          <div style={{padding:"75% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/408220402?h=9be79d62ef&title=0&byline=0&portrait=0" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0"}} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            <div>
              <b>Animated Tabs</b>
              <p>In addition to a change in colour, the bottom tabs on the edit screen now animated micro interaction to provide a second visual indicator on tab changes.</p>
            </div>
          </div>
        </div>
      </div>

      <div id="final design" className="even-grid">
        <h3 style={{padding: "12px 0"}}>Final design</h3>
        <img className="img" src={Hifi} style={{borderRadius: "0"}}/>
      </div>
      <div id="extended deadline" className="text-paragraph">
        <h3 style={{padding: "12px 0"}}>Extended deadline</h3>
          <p>Just before Go Studio FX was due to launch in December 2018, Go Twin was delayed until early 2020 due to manufacturing issues.</p>
          <p>In October 2019, me and Jesper had some downtime, so we decided to revisit Go Studio FX. We looked at our log history of work that was supposed to be done after the previous launch, and saw that the only major feature that was left to implement was support for multiple orientations. We decided that this would be an essential improvement to be done before the new launch.</p>
          <p>I called a stakeholder meeting and presented a business proposal to justify the changes to be made for the new app.</p>
          <Link className="link" to="https://drive.google.com/open?id=1oG8SVgZQMDlOcKvXnGgO_b2AvimQ2ulw" target='_blank'>View proposal</Link>
      </div>

      <div id="conclusion" className="text-paragraph">
        <h3 style={{padding: "12px 0"}}>Conclusion</h3>
          <p>Go Studio FX is TC Helicon’s answer for singer/songwriters to improve social content, and better engage with their fans. It is also a first touch point for artists to experience, and test out TC Helicon’s famous vocal effects. Brand awareness is expected to increase as users begin to post content on social media platforms such as YouTube, Instagram, Facebook and Tik Tok. Go Studio FX was shipped to the <Link className="link" to="https://apps.apple.com/ao/app/go-studio-fx/id1195139936" target='_blank'>iOS App Store</Link> on Aug 5, 2020.</p>
      </div>

      <div id="additional discussion" className="text-paragraph">
        <h3 style={{padding: "12px 0"}}>Additional discussion</h3>
          <p>Points which are not covered in this case study, but are worth discussing in person:</p>
          <ul>
            <p>
              <li>A/B test & evaluation</li>
              <li>Accessibility</li>
              <li>Product strategy & timeline</li>
              <li>Internal feedback and discussions</li>
            </p>
          </ul>
      </div>


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={Blender} alt="Blender project"/>
            <div>
              <h6>Blender</h6>
              <Link className="explore" to="/blender" style={{ color: "var(--brand-foreground)"}}>Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default GoStudioFXProject