import React from "react";
import './footer.scss';
import {EmailButton,LinkedInButton,DribbbleButton,GithubButton} from "../../components/button/index";

const Footer = () => {
  return <div className="footer">
    <div className="buttons">
      <div className="item"><LinkedInButton /></div>
      <div className="item"><EmailButton /></div>
      <div className="item"><DribbbleButton /></div>
      <div className="item"><GithubButton /></div>
    </div>
    <p>© 2024 Ravin Perera's Portfolio</p>
  </div>;
};

export default Footer