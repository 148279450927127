import React from "react";
import './button.scss';
import EmailIcon from '../../assets/icons/Email.svg';

const EmailButton = () => {
  return <div 
    onClick={()=>window.open("mailto:ravinbperera@gmail.com")}
      className="icon-button"><img src={EmailIcon} alt="Email Button" style={{ width: "24px", marginRight: "8px" }} />
    <h5>Email</h5>
  </div>;
};

export default EmailButton;