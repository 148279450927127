import React from "react";
import './samana.scss';
import { Link } from 'react-router-dom';
import GoStudioFX from "../../assets/images/gsfx/gsfx-cover.png"
import Samana from "../../assets/images/samana/samana-cover.png"
import KF1 from "../../assets/images/samana/samana-kf1.png"
import KF2 from "../../assets/images/samana/samana-kf2.png"
import KF3 from "../../assets/images/samana/samana-kf3.png"
import KF4 from "../../assets/images/samana/samana-kf4.png"
import S1 from "../../assets/images/samana/samana-s1.png"
import S1S from "../../assets/images/samana/samana-s1s.png"
import S2 from "../../assets/images/samana/samana-s2.png"
import S2S from "../../assets/images/samana/samana-s2s.png"
import Old from "../../assets/images/samana/samana-old.png"
import Clickup from "../../assets/images/samana/samana-clickup.png"
import Inspiration from "../../assets/images/samana/samana-inspiration.png"
import Lofi from "../../assets/images/samana/samana-lofi.png"
import Medfi from "../../assets/images/samana/samana-medfi.png"
import Hifi from "../../assets/images/samana/samana-hifi.png"
import SLaunch from "../../assets/images/samana/samana-slaunch.png"
import Analytics from "../../assets/images/samana/samana-analytics.png"
import Styles from "../../assets/images/samana/samana-styles.png"
import Grids from "../../assets/images/samana/samana-grids.png"
import CEP from "../../assets/images/samana/samana-cep.png"
import Logo from "../../assets/logos/samana-logo.svg"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const SamanaProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Samana</h1>
          <h5>Co-founder, Poduct designer / May 2020 - Jan 2021</h5>
        </div>
        </div>
          <img className="img" src={Samana} alt="Loop Integration in Teams project cover" />
          <div id="paragraph" className="even-grid">
            <p>We noticed that the number of support services in Sri Lanka are limited and can be hard to find. To solve this, we created <Link className="link" to="https://www.samana.lk/support-list" target="_blank">Samana</Link>, a list of trustworthy mental health services that is easily accessible to everyone. Services on the list are gathered through the reccomendation of medical professionals and social workers.</p>
            <div>
              <b>My role</b>
              <p>I was the Co-founder, and product designer for this project. My responsibilities included: concept development, user research, user interface design, and project managing the work that went in to engineering and design.</p>
            </div>
          </div>
        </div>

      <div className="key-features">
        <h3>Key Features</h3>
        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Over 40 local support services</h6>
            <p>Samana surfaces important information such as service cost, LGBTQIA+ friendly, to make it easier to find the right service.</p>
          </div>
          <img className="features-img" src={KF1}/>
        </div>
        <div id="feature 2" className="features-grid-even">
          <img className="features-img" src={KF2}/>
          <div className="features-grid-text-even">
            <h6>Filter to find the right service for you</h6>
            <p>9 categories of service types that you can filter through.</p>
          </div>
        </div>
        <div id="feature 3" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Share your experience</h6>
            <p>To improve the quality of services hosted on the list, users can anonymously share feedback of past experiences.</p>
          </div>
          <img className="features-img" src={KF3}/>
        </div>
        <div id="feature 4" className="features-grid-even">
          <img className="features-img" src={KF4}/>
          <div className="features-grid-text-even">
            <h6>Available in English, Sinhala, and Tamil</h6>
            <p>Samana is available to all Sri Lankans no matter which language they speak.</p>
          </div>
        </div>
      </div>

      <div id="background">
        <div>
          <h3 style={{padding: "12px 0"}}>Background</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>Samana was born out of necessity in May 2020 when my friend and co-founder, Sharan Velauthan, recognized a critical gap in mental health support services in Sri Lanka. Sharan, already a prominent advocate for mental health awareness on social media platforms, found himself inundated with messages from individuals seeking guidance and support during times of distress. Recognizing the urgent need for a centralized resource, Sharan took the initiative to compile a comprehensive contact list of reputable mental health services across Sri Lanka.</p>
        </div>
        <img className="img" src={Old}/>
        <div className="text-paragraph">
          <p>I approached Sharan with the idea of transforming his Google Sheet into a dynamic, user-friendly online platform. Together, we envisioned Samana as more than just a repository of contacts; it would be a holistic resource offering information, guidance, and community support in all three languages for individuals navigating mental health challenges in Sri Lanka.</p>
          <p>Driven by our shared passion for mental health advocacy and our desire to make a meaningful impact, we embarked on the journey of building Samana.</p>
        </div>
        </div>
      </div>

      <div id="planning">
        <div>
          <h3 style={{padding: "12px 0"}}>Planning</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>We wanted to learn more about the mental health space in Sri Lanka. We conducted interviews with medical professionals, social workers, survivors, and friends to understand the current mental health space in Sri Lanka.</p>
        </div>
        <div className="column-grid">
          <div>
            <b>Participants</b>
            <ul className="text-paragraph">
              <p>
                <li>
                  AIDs survivor / LGBTQIA+ advocate
                </li>
                <li>
                  Domestic abuse survivor
                </li>
                <li>
                  UN Youth Envoy
                </li>
                <li>
                  Co-founder of a mental health service 
                </li>
                <li>
                  LGBTQIA+ advocate and lawyer
                </li>
                <li>
                  Behavioural health researcher
                </li>
              </p>
            </ul>
          </div>
          <div>
            <b>Script (Not all questions are listed)</b>
            <ul className="text-paragraph">
              <p>
                <li>
                  How do you feel about support services in Sri Lanka?
                </li>
                <li>
                  How do you feel about the conversation around mental health and advocacy?
                </li>
                <li>
                  Can you give us a brief outline of how you discovered services?
                </li>
                <li>
                  How do you feel about social media in regards to social support? 
                </li>
                <li>
                  How did you vet the service?
                </li>
                <li>
                  Have you had any bad experiences with support services?
                </li>
                <li>
                  What do you think made it a bad experience?
                </li>
                <li>
                  Do you talk about these issues with your family and friends? Why?
                </li>
                <li>
                  Is the conversation around mental health and mental health services between your friends different from your family?
                </li>
                <li>
                  Do you have or are you part of a support service (could be among your friends)?
                </li>
                <li>
                  What do you think are some barriers that Sri Lankans face to reaching these services?
                </li>
                <li>
                  What are some ways you think Sri Lanka can be more inclusive?
                </li>
              </p>
            </ul>
          </div>
        </div>
        </div>
      </div>

      <div id="problem space">
        <div>
          <h3 style={{padding: "12px 0"}}>Problem space</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>After the interviews were completed, we summarized our findings in to four key points:</p>
        </div>
        <div className="finding-grid">
            <div>
              <div id="finding 1" className="finding">
                <h5 style={{textAlign: "center"}}>Mental health is a taboo topic in Sri Lanka</h5>
              </div>
              <div id="finding 2" className="finding">
                <h5 style={{textAlign: "center"}}>Not all existing services are trust worthy and helpful</h5>
              </div>
            </div>
            <div>
              <div id="finding 3" className="finding">
                <h5 style={{textAlign: "center"}}>Awareness of services are mainly through word of mouth</h5>
              </div>
              <div id="finding 4" className="finding">
                <h5 style={{textAlign: "center"}}>Current services lack a trilingual web presence</h5>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div id="Goals">
        <div>
          <h3 style={{padding: "12px 0"}}>Goals</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>To tackle the problems we created some goals in order for Samana to be successful:</p>
        </div>
        <div className="center-grid">
            <img src={Logo} className="grid-img"/>
            <div className="text-paragraph">
              <ul>
              <p>
                <li>
                  Trustworthy
                </li>
                <li>
                  Raise awareness of mental health
                </li>
                <li>
                  Trilingual
                </li>
                <li>
                  Accessible
                </li>
              </p>
            </ul>
          </div>
        </div>
        </div>
      </div>

      <div id="phases">
        <div>
          <h3 style={{padding: "12px 0"}}>Phases</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>We had big plans for Samana, but we knew we couldn't tackle everything at once. So, we split up our tasks into different phases to create a roadmap.</p>
        </div>
        <div className="column-grid">
          <div>
            <b>Phase 1</b>
            <ul className="text-paragraph">
              <p>
                <li>
                  Website
                </li>
                <li>
                  Support list - Stage 1
                </li>
                <li>
                  Legal disclaimer and terms of user
                </li>
                <li>
                  Translations
                </li>
                <li>
                  Visual language - branding, illustrations
                </li>
                <li>
                  Support list verification process
                </li>
                <li>
                  Feedback
                </li>
                <li>
                  Analytics integration
                </li>
              </p>
            </ul>
          </div>
          <div>
            <b>Phase 2</b>
            <ul className="text-paragraph">
              <p>
                <li>
                  Funding and research proposals
                </li>
                <li>
                  Content - articles and blogs
                </li>
                <li>
                  Awareness campaigns
                </li>
                <li>
                  Partnerships and outreach
                </li>
                <li>
                  Social media
                </li>
              </p>
            </ul>
          </div>
        </div>
        <img src={Clickup} className="img" style={{borderRadius: "0"}}/>
        </div>
      </div>

      <div id="design process" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Design process</h3>
          <p>The objective was to have a tested working concept that adhered to our goals and was built to be accessible and scalable.</p>
        </div>
          <div className="even-grid">
          <div className="text-paragraph">
            <b>Inspiration</b>
          </div>
          <img className="img" src={Inspiration}/>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <b>Wireframes</b>
          </div>
          <img className="img" src={Lofi}/>
          <img className="img" src={Medfi}/>
          <img className="img" src={Hifi}/>
        </div>
      </div>

      <div id="visual design" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Visual design</h3>
          <div className="text-paragraph">
            <p>To make Samana scalable as possible, I invested a significant amount of time on the style guide. Samana contains styles for text, colours, components and even grids and drop shadows.</p>
            <p>All colours follow WCAG 2.0 accessibility guidelines</p>
          </div>
        </div>
          <div className="even-grid">
            <img className="img" src={Styles} style={{borderRadius: "0"}}/>
            <img className="img" src={Grids} style={{borderRadius: "0"}}/>
          </div>
      </div>

      <div id="content editing portal" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Content editing portal</h3>
          <div className="text-paragraph">
            <p>I also designed a content editing portal where admins can go access to add and edit services.</p>
          </div>
        </div>
          <div className="even-grid">
            <img className="img" src={CEP} style={{borderRadius: "0"}}/>
          </div>
      </div>


      <div id="focus groups">
        <div>
          <h3 style={{padding: "12px 0"}}>Focus groups</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>We conducted studies with two focuse groups to validate the design.</p>
        </div>
          <div className="finding-grid" style={{justifyContent: "start"}}>
              <div id="finding 1" className="finding">
                <h5 style={{textAlign: "center"}}>Therapists</h5>
              </div>
              <div id="finding 2" className="finding">
                <h5 style={{textAlign: "center"}}>Experts and advocates</h5>
              </div>
          </div>
          <div className="even-grid-med">
            <div className="even-grid">
              <div>
                <b>Suggestion #1</b>
                <p>Suggestion to provide extra information about a service that could help someone make a decision.</p>
              </div>
              <img className="img" src={S1} style={{borderRadius: "0"}}/>
              <div>
                <b>Addition of secondary tags</b>
                <p>We added secondary tags to communicate extra information such as if there is a cost involved with the service, and if the service is LGBTQIA+ friendly.</p>
              </div>
              <img className="img" src={S1S} style={{borderRadius: "0"}}/>
            </div>
            <div className="even-grid">
              <div>
                <b>Suggestion #2</b>
                <p>The illustrations didn't accurately reflect Sri Lankan people. At first, I opted for readily available illustrations to save time. However, we realized the significance of representing Sri Lankans authentically. It's crucial to foster trust and convey that Samana is inclusive of all Sri Lankans, regardless of their background, beliefs, or culture.</p>
              </div>
              <img className="img" src={S2} style={{borderRadius: "0"}}/>
              <div>
                <b>Updated illustrations from a local illustrator</b>
                <p>We were so fortunate to have the talented Danushri Welikala volunteer her time to make custom illustrations that were more inclusive of all Sri Lankans.</p>
              </div>
              <img className="img" src={S2S} style={{borderRadius: "0"}}/>
            </div>
          </div>
        </div>
      </div>

      <div id="launch" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Launch</h3>
          <div className="text-paragraph">
            <p>Once the website was built and ready, we had a soft launch on November 26th, 2020 to all the amazing individuals (research participants, volunteers, friends) that helped bring Samana to life. Samana went live on January 21, 2020.</p>
          </div>
        </div>
          <div className="even-grid">
            <img className="img" src={SLaunch} style={{borderRadius: "0"}}/>
          </div>
      </div>

      <div id="analytics" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Analytics</h3>
          <div className="text-paragraph">
            <p>I collaborated with our engineers to integrate Google analytics on Firebase, enabling us to monitor click events on Samana. Importantly, this tracking system does not retain any data that could identify individuals; rather, it serves as a tool to enhance Samana's functionality.</p>
          </div>
        </div>
          <div className="even-grid">
            <img className="img" src={Analytics} style={{borderRadius: "0"}}/>
          </div>
      </div>

      <div id="summary" className="even-grid-med">
        <div>
          <h3 style={{padding: "12px 0"}}>Summary</h3>
          <div className="even-grid">
            <p><Link className="link" to="https://www.samana.lk/support-list" target="_blank">Samana</Link> released in January 2020, and was very successful in becoming the first ever centralized trilingual list of trustworthy mental health services in Sri Lanka. The website received an overwhelmingly positive response, with numerous individuals reaching out to express how it had helped them in their time of need, and also to find mental health services they never knew existed. Bakamoono.lk is a well known mental health service in Sri Lanka also wrote an <Link className="link" to="https://www.bakamoono.lk/en/article/6087/samana-addressing-mental-health-support-in-sri-lanka-formally-launches" target="_blank">article</Link> about Samana</p>
            <div className="center-grid">
              <div id="finding 1" className="finding">
                <h5 style={{textAlign: "center"}}>Successful launch with over 1000 daily visitors in the first month.</h5>
              </div>
              <div id="finding 2" className="finding">
                <h5 style={{textAlign: "center"}}>Over 20 volunteers that actively helped make Samana a reality</h5>
              </div>
              <div id="finding 3" className="finding">
                <h5 style={{textAlign: "center"}}>Many learnings along the way</h5>
              </div>
        </div>
        <div className="text-paragraph">
            <p>This project took a village to build, with so many humans volunteering their time to make an impact on an issue they all believe in. Translators, developers, designers, illustrators, lawyers, researchers, mental health and psychosocial consultants are a few of the many different volunteers who's contributions without a doubt was the reason for the success of Samana.</p>
            <p>This write-up barely scratches the surface of all the effort that went into building Samana. Here are some of the areas not covered:</p>
            <ul>
              <p>
                <li>
                  Who we are page
                </li>
                <li>
                  Contact us page
                </li>
                <li>
                  Branding and visual design process
                </li>
                <li>
                  Translation process
                </li>
                <li>
                  Legal process
                </li>
                <li>
                  Social media planning
                </li>
                <li>
                  Decision making process
                </li>
                <li>
                  Verification of recommended services
                </li>
                <li>
                  Future phases planning
                </li>
              </p>
            </ul>
          </div>
          </div>
        </div>
      </div>

      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={GoStudioFX} alt="Go Studio FX project" />
            <div>
              <h6>Go Studio FX</h6>
              <Link className="explore" to="/go-studio-fx">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default SamanaProject