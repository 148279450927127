import React from "react";
import { Link } from 'react-router-dom';
import './loop-integration.scss';
import LoopIntegration from "../../assets/images/loop-integration/loop-integration-cover.png"
import LightningLoop from "../../assets/images/loop-integration/lightning-loop.png"
import LoopTab from "../../assets/images/loop-integration/loop-tab.png"
import LoopPaywall from "../../assets/images/loop-paywall/loop-paywall-cover.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"


const LoopIntegrationProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Loop integrations in Teams</h1>
          <h5>Product designer / Feb 2023 - Feb 2024</h5>
        </div>
        </div>
          <img className="img" src={LoopIntegration} alt="Loop Integration in Teams project cover" />
          <div id="paragraph" className="text-paragraph">
            <p>I led the design of integrating features of Microsoft Loop into Microsoft Teams, creating a transformative enhancement for collaboration within the popular communication platform.</p>
            {/* <p>I worked on two main projects that fell under the umbrella of Loop integration in Teams:</p>
            <ul>
              <p>
                <li>Lightning Loop: A Loop page that comes with every Teams chat.</li>
                <li>Loop as a tab: Ability to pin workspaces, pages as a tab in Teams.</li>
              </p>
            </ul> */}
          </div>
        </div>
      {/* <div id="lightning loop section">
        <div>
          <h3 style={{padding: "12px 0"}}>Lightning Loop</h3>
          <p>Lightning Loop is a new concept that associates a permanent Loop page with every Teams chat. Its goal is to make it easier to collaborate on Loop content side-by-side with the chat, giving the content a durable entry point and more space than with a Loop component in the chat stream.</p>
        </div>
        <img className="img" src={LightningLoop} alt="Lightning Loop" />
      </div>
      <div id="Loop as a tab section">
        <div>
          <h3 style={{padding: "12px 0"}}>Loop as a tab</h3>
          <p> Embedding Microsoft Loop directly as a tab within Microsoft Teams. This innovation offers users convenient access to Loop workspaces and pages without leaving the familiar Teams environment.</p>
        </div>
        <img className="img" src={LoopTab} alt="Loop as a tab" />
      </div> */}
      
      <div id="Interested to see more?" className="see-more">
        <h3 style={{padding: "12px 0"}}>Interested to see more?</h3>
        <p>This work is under NDA and I am limited to what I can share publicly until it is shipped to general audience. If you are interested about hearing about them, please feel free to reach out.</p>
      </div>
      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={LoopPaywall} alt="Lightning paywall" />
            <div>
              <h6>Loop paywall</h6>
              <Link className="explore" to="/loop-paywall">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default LoopIntegrationProject