import React from "react";
import { Link } from 'react-router-dom';
import './teamwrk.scss';
import Teams from "../../assets/images/loop-integration/loop-integration-cover.png"
import Teamwrk from "../../assets/images/teamwrk/teamwrk-cover.png"
import Old from "../../assets/images/teamwrk/teamwrk-old.png"
import New from "../../assets/images/teamwrk/teamwrk-new.png"
import Responsive from "../../assets/images/teamwrk/teamwrk-responsive.png"
import Elevation from "../../assets/images/teamwrk/teamwrk-elevation.png"
import Grid from "../../assets/images/teamwrk/teamwrk-grid.png"
import Styles from "../../assets/images/teamwrk/teamwrk-styles.png"
import Groups from "../../assets/images/teamwrk/teamwrk-groups.png"
import Call from "../../assets/images/teamwrk/teamwrk-call.png"
import Email from "../../assets/images/teamwrk/teamwrk-email.png"
import Files from "../../assets/images/teamwrk/teamwrk-files.png"
import Posts from "../../assets/images/teamwrk/teamwrk-posts.png"
import Survey1 from "../../assets/images/teamwrk/teamwrk-survey1.png"
import Survey2 from "../../assets/images/teamwrk/teamwrk-survey2.png"
import Roles from "../../assets/images/teamwrk/teamwrk-roles.png"
import Mobile from "../../assets/images/teamwrk/teamwrk-mobile.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const TeamwrkProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Teamwrk</h1>
          <h5>Poduct designer / May 2017 - Sep 2017</h5>
        </div>
        </div>
          <img className="img" src={Teamwrk} alt="Teamwrk project cover" />
          <div id="paragraph">
            <p>Teamwrk is the first ever product I designed professionally. It is a white labbeled CRM platform where clients can manage internal communications and files within thier organization.</p>
          </div>
      <div id="challenge">
        <div>
          <h3 style={{padding: "12px 0"}}>Challenge</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <h4>Develop a new version with an improved user flow inspired by the old platform.</h4>
          <p>Collaborative Media Group provides their clients with a white labelled platform containing a set of features to manage internal communications within a company. Over a period of 5 months, I was tasked with the design of a new version which enhances the features of the old system, and simplifies the user experience in a more visually appealing manner.</p>
        </div>
        <div className="column-grid">
            <div className="even-grid">
              <b>Before</b>
              <img className="img" src={Old}/>
              <ul className="text-paragraph">
                <p>
                  <li>
                    Contains many features, but lacks integration
                  </li>
                  <li>
                    Visually cluttered
                  </li>
                  <li>
                    System is designed for desktop view only
                  </li>
                </p>
              </ul>
            </div>
            <div className="even-grid">
              <b>After</b>
              <img className="img" src={New}/>
              <ul className="text-paragraph">
                <p>
                  <li>
                    Feature integration
                  </li>
                  <li>
                    Minimalistic design
                  </li>
                  <li>
                    Responsive for desktop, tablet, and mobile
                  </li>
                </p>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div id="design guidelines">
        <div>
          <h3 style={{padding: "12px 0"}}>Design guidelines</h3>
        </div>
        <div className="even-grid">
          <p>Using Google’s material design guidelines, I was able to design a responsive layout for the breakpoints for 320dp, 360dp, 480dp, 600dp, 720dp, and 1440dp. Elevation provides each element with a hierachy on where it sits on the layout.</p>
          <img className="img" src={Responsive}/>
          <img className="img" src={Elevation}/>
          <img className="img" src={Grid}/>
          <img className="img" src={Styles}/>
        </div>
      </div>
      <div id="user interface">
        <div>
          <h3 style={{padding: "12px 0"}}>User interface</h3>
        </div>
        <div className="even-grid-med">
          <div className="even-grid">
            <div>
              <b>Group based activity</b>
              <p>Create groups and personalize the system based on each group.</p>
            </div>
            <img className="img" src={Groups}/>
          </div>
          <div className="even-grid">
            <div>
              <b>Assign roles</b>
            </div>
            <img className="img" src={Roles}/>
          </div>
          <div className="even-grid">
            <div>
              <b>Create posts and share them with your groups</b>
            </div>
            <img className="img" src={Posts}/>
          </div>
          <div className="even-grid">
            <div>
              <b>Upload, share, and manage files</b>
            </div>
            <img className="img" src={Files}/>
          </div>
          <div className="even-grid">
            <div>
              <b>Create forms to survey and get feedback</b>
            </div>
            <img className="img" src={Survey1}/>
            <img className="img" src={Survey2}/>
          </div>
          <div className="even-grid">
            <div>
              <b>Communicate via message, audio, or video calls</b>
            </div>
            <img className="img" src={Call}/>
          </div>
        </div>
      </div>
      <div id="responsive ui" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Responsive UI</h3>
        </div>
        <div>
          <img className="img" src={Mobile}/>
        </div>
      </div>
      <div id="email templates" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Email templates</h3>
        </div>
        <div>
          <img className="img" src={Email}/>
        </div>
      </div>



      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={Teams} alt="Loop integration in Teams project" />
            <div>
              <h6>Loop integration in Teams</h6>
              <Link className="explore" to="/loop-integration">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default TeamwrkProject