import React from "react";
import { Link } from 'react-router-dom';
import './blender.scss';
import Blender from "../../assets/images/blender/blender-cover.png"
import Do1 from "../../assets/images/blender/blender-do1.png"
import Do2 from "../../assets/images/blender/blender-do2.png"
import Early from "../../assets/images/blender/blender-early.png"
import Competitors from "../../assets/images/blender/blender-competitors.png"
import Styles from "../../assets/images/blender/blender-styles.png"
import Insp from "../../assets/images/blender/blender-insp.png"
import Mocks from "../../assets/images/blender/blender-mocks.png"
import BT from "../../assets/images/blender/blender-bt.png"
import BT2 from "../../assets/images/blender/blender-bt2.png"
import BT3 from "../../assets/images/blender/blender-bt3.png"
import BT4 from "../../assets/images/blender/blender-bt4.png"
import Card1 from "../../assets/images/blender/blender-card1.png"
import Card2 from "../../assets/images/blender/blender-card2.png"
import Card3 from "../../assets/images/blender/blender-card3.png"
import Sol1 from "../../assets/images/blender/blender-solution1.png"
import Sol2 from "../../assets/images/blender/blender-solution2.png"
import Sol3 from "../../assets/images/blender/blender-solution3.png"
import Connect from "../../assets/images/blender/blender-bt-connect.gif"
import JackSense from "../../assets/images/blender/blender-jacksense.gif"
import Buttons from "../../assets/images/blender/blender-buttons.gif"
import Hierarchy from "../../assets/images/blender/blender-hierarchy.png"
import Accessibility from "../../assets/images/blender/blender-accessibility.png"
import Review from "../../assets/images/blender/blender-review.png"
import Findings from "../../assets/images/gsfx/gsfx-findings.png"
import Takeaways from "../../assets/images/gsfx/gsfx-takeaways.png"
import Goxlr from "../../assets/images/goxlr/goxlr-cover.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const BlenderProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Blender</h1>
          <h5>Product designer / May 2018 - Aug 2018</h5>
        </div>
        </div>
          <img className="img" src={Blender} alt="Blender project cover" />
          <div id="paragraph" className="text-paragraph">
            <p>Finding a place to practice can be a challenge for bands. Not everyone has access to locations with good sound isolation, and renting a studio can get expensive. TC Helicon’s Blender aims to solve this issue by facilitating silent jam sessions.</p> 
            <p style={{fontWeight: "600", fontStyle: "italic"}}>Blender is a portable audio mixer that allows performers to plug in their instruments and listen through their headphones. This way bands can practice through the night without having to worry about disturbing their neighbors</p>
            <p>I was the Sole Product Designer for the Blender app. My responsibilities included user research, interaction, visual design, prototyping and testing.</p>
            <p>The app was launched on the <Link className="link" to="https://apps.apple.com/ca/app/blender/id1316749729" target='_blank'>iOS App Store</Link>, and the <Link className="link" to="https://play.google.com/store/apps/details?hl=en&id=tchelicon.com.blenderappandroid" target='_blank'>Google Play Store</Link> on <span style={{fontWeight: "600"}}>Feb 6, 2019.</span></p>
          </div>
          <iframe className="youtube-video" src="https://www.youtube.com/embed/JjwRGsmBOkk?si=o3lUV9u_G9eRZPbP" title="YouTube video player" style={{border:"0"}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    <div id="early concept">
      <div>
        <h3 style={{padding: "12px 0"}}>Early concept</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>We looked at the old app and tried to identify opportunities where user-facing problems needed to be improved and refined. Next, those opportunities were challenged with interpretive questions in the form of hypotheses.</p>
          <ul>
            <p>
              <li>What changes would make the app valuable to more people?</li>
              <li>Are there any tensions or inconsistencies with the current interface that affects the user experience?</li>
            </p>
          </ul>
        </div>
        <img className="img" src={Early} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div id="design objective" className="even-grid">
      <div>
        <h3 style={{padding: "12px 0"}}>Design objective</h3>
        <h4 style={{padding: "12px 0"}}>Design a product that makes the TC Helicon experience more accessible and increase brand awareness among younger users.</h4>
        <p>Previous research revealed that TC Helicon products struggle to grab the attention of young/new singer songwriters, because there was no way other than by purchasing a product to experience their vocal effects. This created a barrier for customers who were not sure which TC Helicon product/vocal effects were ideal for their performance.</p>
      </div>
      <div className="column-grid">
      <img className="img" src={Do1} style={{borderRadius: "0"}} />
      <img className="img" src={Do2} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div className="column-grid">
        <div id="column 1" className="column">
          <div className="column-heading">
            <img src={Findings} style={{maxWidth: "200px"}}/>
            <h6 >Observations</h6>
          </div>
            <div>
              <b>Instrument levels</b>
              <p>The instrument levels were adjusted once on average per performance.</p>
            </div>
            <div>
              <b>Identifying inputs</b>
              <p>Users had trouble Identifying which input level to adjust. They looked at the cables going in to the Blender device before adjusting.</p>
            </div>
            <div>
              <b>Time window</b>
              <p>Drummers and guitarists adjusted levels before a performance, or very quickly during a performance due to a short time window.</p>
            </div>
        </div>
        <div id="column 2" className="column">
          <div className="column-heading">
            <img src={Takeaways} style={{maxWidth: "200px"}}/>
            <h6 >Takeaways</h6>
          </div>
            <div>
              <b>Reduce clicks</b>
              <p>Adjusting a level on the Blender device takes two clicks. Performers usually have a very short period of time to adjust levels, so reducing clicks is important.</p>
            </div>
            <div>
              <b>Customize</b>
              <p>Ability to customize the output on the app via icons, colours, etc.</p>
            </div>
            <div>
              <b>Visibility</b>
              <p>Due to the small window, it is important to make controls/components visible and visually contrasted.</p>
            </div>
        </div>
      </div>
      <div id="learning from others">
      <div>
        <h3 style={{padding: "12px 0"}}>Learning from others</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>Before jumping in to the new design, I did some market research along with a competitor analysis to identify any standards and design consistencies that could help users understand our app.</p>
          <p>After looking at many apps related to audio, I identified that skeumorphic design was very common among audio apps. This style is mainly used to evoke a sense of nostalgia with physical devices. The downside is that it is not scalable and adds limitations to designs. I decided to go with a hybrid design that emulates skeuomorphic buttons through flat design to help the design be responsive without compromising familiarity to the Blender device.</p>
        </div>
        <img className="img" src={Competitors} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div id="colour and typography">
      <div>
        <h3 style={{padding: "12px 0"}}>Colour and typography</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>For brand consistency, the colour choices for the Blender app was based on looking at TC Helicon products.</p>
          <p>I mainly gathered inspiration from the other TC Helicon products, particularly Blender’s product line known as the Go Series. The dark theme helps to visually contrast elements, and avoids distracting musicians during performances.</p>
          <p>I used the typeface Flama for the buttons, to keep consistent with the physical device. Native fonts San Fransisco Pro (iOS), and Roboto (Android) were used for all other text applications.</p>
        </div>
        <img className="img" src={Insp} style={{borderRadius: "0"}} />
        <img className="img" src={Styles} style={{borderRadius: "0", maxWidth: "800px", margin: "0 auto"}} />
      </div>
    </div>
    <div id="mixer screen">
      <div>
        <h3 style={{padding: "12px 0"}}>Mixer screen</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>I started sketching ways to merge the gap between the physical and virtual experience of Blender. Having access to renders and assets, I considered the use of an interactive prototype of the Blender device. Using an interactive prototype added a few benefits to the app:</p>
          <ul>
            <p>
              <li>Keeps navigation consistent between the device and the app.</li>
              <li>Shows active and inactive states (i.e. which outputs are connected)</li>
            </p>
          </ul>
        </div>
        <img className="img" src={Mocks} style={{borderRadius: "0"}} />
        <div>
          <b>Hierarchy</b>
          <p>It was important to consider the user interaction of setting up before a performance. The mixer screen is divided in to three parts based on frequency of usage.</p>
        </div>
        <img className="img" src={Hierarchy} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div id="bluetooth">
      <div>
        <h3 style={{padding: "12px 0"}}>Bluetooth</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>One of the main features of the Blender app on iOS is the ability to create a Bluetooth network that can connect multiple iOS devices to Blender. </p>
          <p>The Bluetooth connection requires a device to broadcast a connection, and the other device to search the connection.</p>
        </div>
        <img className="img" src={BT} style={{borderRadius: "0", maxWidth: "600px", margin: "0 auto"}} />
        <div className="text-paragraph">
          <p>One of the main pain points was that users did not know which device was the primary connection when multiple devices were connected.</p>
          <h6 style={{fontStyle: "italic"}}>Disconnecting the primary device causes all the secondary devices to disconnect, so it was important to visually communicate which device is connected.</h6>
          <p>I began iterating ways of visually showing Bluetooth connections.</p>
        </div>
        <img className="img" src={BT2} style={{borderRadius: "0"}} />
        <div className="column-grid">
          <div>
            <img className="img" src={BT3} style={{borderRadius: "0", maxWidth: "400px", margin: "0 auto", paddingBottom: "24px"}} />
            <p>Connected to Blender (Primary)</p>
          </div>
          <div>
            <img className="img" src={BT4} style={{borderRadius: "0", maxWidth: "400px", margin: "0 auto", paddingBottom: "24px"}} />
            <p>Connected to a Host (Secondary)</p>
          </div>
        </div>
      </div>
    </div>
    <div id="interactions">
      <div>
        <h3 style={{padding: "12px 0"}}>Interactions</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>The main idea behind the interactions on the Blender app is to educate and grab attention. I tried to incorporate as many possible ways the app can react to an user interaction.</p>
        </div>
        <div>
          <b>Connecting animation</b>
          <p>Shows which devices are currently being connected.</p>
        </div>
        <img className="img" src={Connect} style={{borderRadius: "0", marginBottom: "40px"}} />
        <div>
          <b>Jack sense</b>
          <p>Shows when and where a user plugs in an output.</p>
        </div>
        <img className="img" src={JackSense} style={{borderRadius: "0", marginBottom: "40px"}} />
        <div>
          <b>Button press</b>
          <p>Indicates the button states while giving a nostalgic feel to physical object.</p>
        </div>
        <img className="img" src={Buttons} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div id="solutions from the usability study" className="even-grid-medium">
      <div>
        <h3 style={{padding: "12px 0"}}>Solutions from the usability study</h3>
        <p>After the app had been completed, we conducted a usability study with TC Helicon employees.</p>
      </div>

        <div id="solution 1" className="column-grid">
          <div>
            <b>1. Connect screen</b>
            <p>The new design includes a copy of the instructions on the connect screen. The tests revealed that the participants had no confusions connecting on the updated app.</p>
          </div>
          <img className="img" src={Card1} style={{borderRadius: "0", maxWidth: "400px"}} />
        </div>
        <img className="img" src={Sol1} style={{borderRadius: "0", marginBottom: "80px"}} />

        <div id="solution 2" className="column-grid">
          <div>
            <b>2. Input jack sense</b>
            <p>Input jack sense was initially added to the spec of the Blender app, but was taken out due to hardware reasons. By the time we did the usability study, the hardware issues were fixed, but the app was not updated. The new design shows the inactive inputs greyed out.</p>
          </div>
          <img className="img" src={Card2} style={{borderRadius: "0", maxWidth: "400px"}} />
        </div>
        <img className="img" src={Sol2} style={{borderRadius: "0", maxWidth: "800px", margin: "0 auto", marginBottom: "80px"}} />

        <div id="solution 3" className="column-grid">
          <div>
            <b>3. Tool tip</b>
            <p>The new update includes a tool tip to let users know that they can label the inputs. The tool tip appears when the first input is connected to Blender.</p>
          </div>
          <img className="img" src={Card3} style={{borderRadius: "0", maxWidth: "400px"}} />
        </div>
        <img className="img" src={Sol3} style={{borderRadius: "0", maxWidth: "800px", margin: "0 auto", marginBottom: "80px"}} />
    </div>
    <div id="accessibility">
      <div>
        <h3 style={{padding: "12px 0"}}>Accessibility</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>In addition to making components and touch areas larger from the old app, the new app also fetaures colours based on <Link className="link" to="https://www.w3.org/WAI/standards-guidelines/wcag/" target='_blank'>Web Content Accessibility Guidelines 2.0</Link> (WCAG) colour standards.</p>
        </div>
        <img className="img" src={Accessibility} style={{borderRadius: "0"}} />
      </div>
    </div>
    <div id="moving forward">
      <div>
        <h3 style={{padding: "12px 0"}}>Moving forward</h3>
      </div>
      <div className="even-grid">
        <div className="text-paragraph">
          <p>In order to understand the user experience in depth, we designed some KPIs that helped us measure the success of the Blender app.</p>
          <p>Since Blender is a product that can run independently without the app, it was important to measure app downloads. If we succeeded in improving the Blender experience with the app, we would see a similar or higher number of app downloads to products sold. Downloads could be affected by other factors such as discoverability. Therefore, we would want to measure engagement with the social media presence of the Blender app, such as views on tutorial videos on YouTube, and posts on Facebook and TC Helicon’s knowledge base.</p>
        </div>
        <img className="img" src={Review} style={{borderRadius: "0", maxWidth: "640px", margin: "0 auto"}} />
      </div>
    </div>


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={Goxlr} alt="GoXLR project" />
            <div>
              <h6>GoXLR</h6>
              <Link className="explore" to="/go-xlr">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default BlenderProject