import React from "react";
import { Link, ScrollRestoration } from 'react-router-dom';
import './loop-paywall.scss';
import MorganStanley from "../../assets/images/morgan-stanley/morgan-stanley-cover.png"
import LoopPaywall from "../../assets/images/loop-paywall/loop-paywall-cover.png"
import Strings from "../../assets/images/loop-paywall/paywall-strings.png"
import Metrics from "../../assets/images/loop-paywall/paywall-metrics.png"
import IA from "../../assets/images/loop-paywall/paywall-ia.png"
import GP from "../../assets/images/loop-paywall/paywall-gp.png"
import GP2 from "../../assets/images/loop-paywall/paywall-gp2.png"
import PGP from "../../assets/images/loop-paywall/paywall-pgp.png"
import PGP2 from "../../assets/images/loop-paywall/paywall-pgp2.png"
import MSA from "../../assets/images/loop-paywall/paywall-msa.png"
import Timeline from "../../assets/images/loop-paywall/paywall-timeline.png"
import SKU from "../../assets/images/loop-paywall/paywall-sku.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const LoopPaywallProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Loop paywall</h1>
          <h5>Poduct designer / Jun 2023 - Sep 2023</h5>
        </div>
        </div>
          <img className="img" src={LoopPaywall} alt="Loop Integration in Teams project cover" />
          <div id="paragraph">
            <p>I was one of two designers involved in designing the paywall experience for Loop customers. In addition to leading the design for the mobile app experience, I was heavily involved with the planning the architecture that is used in the web experience.</p>
          </div>
        </div>

      <div id="challenge">
        <div>
          <h3 style={{padding: "12px 0"}}>Challenge</h3>
        </div>
        <div className="even-grid">
        <div className="text-paragraph">
          <p>June 2023: Loop was in public preview, but we needed to figure out how to build the right affordances to enable income generation before we went to GA in September 2023.</p>
          <p>None of the team had done paywall work before, so there were a lot of known unknowns. The team needed to drive clarity and needed to do it very quickly in order to meet ship dates. Additionally, our business partners were still hashing out specifics on our SKU models, which added to the ambiguity we were already wading through.</p>
          <p>We realized very quickly that we were going to focus only on our AAD tenants for this first round of paywall work. AAD meaning Microsoft customers who had Microsoft 365 enterprise licenses. Our multiple design explorations helped us clarify the scope to building both back- and front-end affordances that allowed us to turn on Loop access by default for specified M365 SKUs: Business Standard and Business Premium and our E3 and E5 enterprise SKUs.</p>
        </div>
        <img className="img" src={SKU}/>
        </div>
      </div>

      <div id="actions">
        <div>
          <h3 style={{padding: "12px 0"}}>Actions</h3>
        </div>
        <div className="even-grid">
          <p>I started my involvement with the paywall work in October 2022 when I was the lead designer on limits in Loop for public preview users. The Loop licensing work started in June 2023, and I joined the team as the mobile designer partnering up with Callie Neylan who led the designs for web collaborating with a core team that involved a PM lead, engineering partners, and business planners. The first phase of the project involved designing the Loop experience for enterprise customers. Here are the actions I took:</p>
          <img className="img" src={Timeline}/>
        </div>
      </div>

      <div id="action 1">
        <div>
          <b>1. Created a journey map for web and mobile</b>
        </div>
        <div className="even-grid">
          <p>Created a journey map on FigJam that helped visualize all the limits, messaging, and various other UX considerations needed for the experience. This was pivotal in helping the core team understand the complex flow, identify gaps, and helped create a backlog of tasks for the team to design and build the experience.</p>
          <img className="img" src={IA}/>
        </div>
      </div>

      <div id="action 2">
        <div>
          <b>2. Created a table to document strings used</b>
        </div>
        <div className="even-grid">
          <p>As there was a lot of messaging involved with various strings. I created a table that hosted the source of truth for all the strings used in the experience and mapped it with the string ID’s in code so engineering can easily reference.</p>
          <img className="img" src={Strings}/>
        </div>
      </div>

      <div id="action 3" className="even-grid">
        <div>
          <b>3. Designed Mobile flows for AAD users</b>
          <p>I translated the web designs for AAD users into mobile flows for the Loop iOS, Android apps.</p>
        </div>
          <div>
            <h4 style={{marginBottom: "12px"}}>During grace period</h4>
            <p>The main focus of the designs for AAD users was to transition them from a free tier (public preview) to a licensed tier. For this we introduced a grace period where users will be given a notice that they will need a license in the future to access Loop.</p>
          </div>
          <div>
            <img className="img" src={GP}/>
            <p style={{marginTop: "16px", textAlign: "center"}}>Home screen during grace period</p>
          </div>
          <div>
            <img className="img" src={GP2} style={{marginTop: "40px"}}/>
            <p style={{marginTop: "16px", textAlign: "center"}}>Messaging when accessing the member roster</p>
          </div>
          <div style={{marginTop: "40px"}}>
          <h4 style={{margin: "12px 0"}}>After grace period</h4>
          <p>AAD members would require a license to use Loop. The messaging needed to change to communicate this state.</p>
          </div>
          <div>
            <img className="img" src={PGP}/>
            <p style={{marginTop: "16px", textAlign: "center"}}>Home screen after the grace period</p>
          </div>
          <div>
            <img className="img" src={PGP2} style={{marginTop: "40px"}}/>
            <p style={{marginTop: "16px", textAlign: "center"}}>The option to add access the member roster will be disabled</p>
          </div>
      </div>
      
      <div id="action 4">
        <div>
          <b>4. Designed messaging for MSA users on mobile</b>
        </div>
        <div className="even-grid">
        <p>I also designed mobile flows for MSA users to accommodate future features such as Microsoft universal storage quota (USQ) due to App store requirements.</p>
          <img className="img" src={MSA}/>
        </div>
      </div>
      <div id="result">
        <div>
          <h3 style={{padding: "12px 0"}}>Result</h3>
        </div>
        <div className="even-grid">
          <p>The licensing model went live to the general audience in <Link to="https://support.microsoft.com/en-us/office/loop-access-via-microsoft-365-subscriptions-92915461-4b14-49a4-9cd4-d1c259292afa" target="_blank" className="link">September 2023.</Link></p>
          <img className="img" src={Metrics}/>
          <p>The teal and orange lines show licensed and grace period users respectively. This shows that the licensing model did not impede Loop's app growth which is its main business model. In terms of customer feedback, we have not received any negative feedback on the licensing model itself.</p>
        </div>
      </div>


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={MorganStanley} alt="Morgan Stanley" />
            <div>
              <h6>Retirement plan sponsor experience by Morgan Stanley</h6>
              <Link className="explore" to="/morgan-stanley">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default LoopPaywallProject