import React from "react";
import { Link } from 'react-router-dom';
import './go-xlr.scss';
import GoXLR from "../../assets/images/goxlr/goxlr-cover.png"
import Teamwrk from "../../assets/images/teamwrk/teamwrk-cover.png"
import Breakdown from "../../assets/images/goxlr/goxlr-breakdown.png"
import KF1 from "../../assets/images/goxlr/goxlr-kf1.png"
import Lighting from "../../assets/images/goxlr/goxlr-lighting.png"
import KF3 from "../../assets/images/goxlr/goxlr-kf3.png"
import KF4 from "../../assets/images/goxlr/goxlr-kf4.png"
import Sketches from "../../assets/images/goxlr/goxlr-sketches.png"
import Layout from "../../assets/images/goxlr/goxlr-layout.png"
import Mixer from "../../assets/images/goxlr/goxlr-mixer.png"
import Scroll from "../../assets/images/goxlr/goxlr-scroll.png"
import UJ from "../../assets/images/goxlr/goxlr-uj.png"
import ONB1 from "../../assets/images/goxlr/goxlr-onb1.png"
import ONB2 from "../../assets/images/goxlr/goxlr-onb2.png"
import ONB3 from "../../assets/images/goxlr/goxlr-onb3.png"
import ONB4 from "../../assets/images/goxlr/goxlr-onb4.png"
import ONB5 from "../../assets/images/goxlr/goxlr-onb5.png"
import ONB6 from "../../assets/images/goxlr/goxlr-onb6.png"
import Mic from "../../assets/images/goxlr/goxlr-mic.png"
import Twitchcon from "../../assets/images/goxlr/goxlr-twitchcon.jpg"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const GoXLRProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>GoXLR</h1>
          <h5>Product designer / May 2018 - Oct 2018</h5>
        </div>
        </div>
          <img className="img" src={GoXLR} alt="GoXLR project cover" />
          <div id="paragraph" className="text-paragraph">
            <p>There is no straightforward way for streamer’s to take full control of their broadcast’s audio. Prior to GoXLR, online streamers had to piece together audio solutions. This made getting great audio a challenge for streamers to their viewers.</p> 
            <p style={{fontWeight: "600", fontStyle: "italic"}}>GoXLR offers an all-in-one desktop recording/processing solution allowing streamers to mix their audio in real time, change their voice, and engage their audience like never before.</p>
            <p>I was the Product Designer for the GoXLR app and my responsibilities included user research, interaction, visual design, and testing.</p>
          </div>
          <iframe className="youtube-video" src="https://www.youtube.com/embed/Ss434teZdng?si=KRAP7b89TzKyWKVy" title="YouTube video player" style={{border:"0"}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <div className="key-features">
        <h3>Key Features</h3>
        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Virtual device</h6>
            <p>The virtual device on the GoXLR mirrors any interaction that happens on the hardware. This also gives feedback to any visual configurations the user makes.</p>
          </div>
          <img className="features-img" src={KF1}/>
        </div>
        <div id="feature 2" className="features-grid-even">
          <img className="features-img" src={Lighting}/>
          <div className="features-grid-text-even">
            <h6>Personalize you GoXLR</h6>
            <p>Customize lighting and icons that show on your device.</p>
          </div>
        </div>
        <div id="feature 3" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Categorization of features</h6>
            <p>Keeping the mic at heart, the feature tabs are organized in the order that a user would have to set up their GoXLR.</p>
          </div>
          <img className="features-img" src={KF3}/>
        </div>
        <div id="feature 4" className="features-grid-even">
          <img className="features-img" src={KF4}/>
          <div className="features-grid-text-even">
            <h6>Routing table</h6>
            <p>The GoXLR app provides an easy to use routing table to configure what you want to hear vs. what you want your viewers to hear.</p>
          </div>
        </div>
      </div>

    <div id="problem" className="even-grid">
      <div>
        <h3 style={{padding: "12px 0"}}>Problem</h3>
        <h4 style={{padding: "12px 0"}}>How might we design the GoXLR app to be streamers focused.</h4>
        <p>The early design concept of the GoXLR lacked a specific focus on catering to the needs of streamers, which became apparent as the product was primarily geared towards professional audio applications. While the initial design boasted impressive audio capabilities suitable for studio environments, it lacked the tailored features and intuitive interface necessary to seamlessly integrate into the workflows of content creators and streamers. This oversight resulted in a disconnect between the product and its target audience, with streamers finding it cumbersome to use and lacking essential functionalities crucial for their live streaming setups. Consequently, the team recognized the necessity for a more streamer-focused approach, prompting subsequent iterations of the GoXLR to prioritize features that specifically catered to the unique demands of content creators in the streaming community.</p>
      </div>
      <img className="img" src={Breakdown}/>
    </div>

    <div id="actions">
        <div>
          <h3 style={{padding: "12px 0"}}>Actions</h3>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <p>Due to time and resources, I was asked by my product manager to reuse the visual design from the early concept and rework the user experience for the configuration section.</p>
            <p>I played around with the app for a week, and noted down any inconsistencies and areas of confusion that I personally felt with the app. Obviously the ideal way to do this would be to test the app with real users and gather unbiased feedback. I spoke to my product manager and he mentioned that it would be a challenge to test the app as the app required the hardware to work, and we did not have enough products to ship at the time. Therefore, we came to an agreement that it would be best to design an MVP that the team approved, and by that time we could order enough products to ship to our users to be tested. Thus began my rework of the GoXLR app.</p>
            <p>These are the actions I took:</p>
          </div>
        </div>
      </div>
      <div id="action 1">
        <div>
          <b>1. Updated the layout of the app to improve navigation</b>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <p>One of the general issues with the early concept was keeping navigation consistent. Users had to navigate between different sections on the app.</p>
            <p>To inform users where they were, I extended the settings preview horizontally and moved all the tabs to this section. Each section on the GoXLR: Mixer, Effects, and Samples had its own tab as well as additional two tabs for Routing and Settings. In order to categorize information, The Mixer, Effects, and Samples section had Settings and Lighting for each so a user could configure everything related to that section. To make mic visible, it was moved under the audio tab alongside routing.</p>
          </div>
          <img className="img" src={Layout} style={{maxWidth: "800px"}}/>
          <div className="text-paragraph">
            <p>I presented three options to my team to show how content could be displayed on the settings display.</p>
          </div>
          <img className="img" src={Sketches} style={{borderRadius: "0"}}/>
          <div className="text-paragraph">
            <p>I presented three options to my team to show how content could be displayed on the settings display. The options mainly was different ways of how lightning options were integrated. The team picked option 3 as it contained the benefits of both option 1 and 2. In addition, the team found option 3 to be more efficient in terms of navigation.</p>
            <p>I used the visual language from the early concept to prepare high fidelity concepts that could be built for testing.</p>
          </div>
          <div className="column-grid">
            <img className="img" src={Mixer} style={{borderRadius: "0"}}/>
            <img className="img" src={Lighting} style={{borderRadius: "0"}}/>
          </div>
          <div>
            <b>Horizonal layout for controls</b>
              <div className="text-paragraph">
                <p>For a horizontal progression, I took inspiration from Finder’s item categorization. The information is arranged in columns containing a list view. The columns give an information hierarchy from left to right, so the user can see the path of how the settings are assigned.</p>
                <p>I read an article published by Nielsen Norman group about horizontal scrolls. The article summarized that users do not naturally expect horizontal scrolls on desktop but do on mobile. For GoXLR, a horizontal layout would add value to most of the parameters such as EQ that read horizontal. Including them in a vertical layout would hide more parameters than a horizontal layout given the aspects of a desktop view. Since the settings are not the main the primary navigation, we decided to add a horizontal layout for the settings section of GoXLR. We took some points from the article to make our horizontal scroll less jaring by adding scroll bars to indicate how much content is left. </p>
              </div>
          </div>
          <img className="img" src={Scroll} style={{borderRadius: "0"}}/>
        </div>
      </div>

      <div id="action 2">
        <div>
          <b>2. Designed a user onboarding/tutorial flow</b>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
          <p>In order to design a successful onboarding/tutorial experience, I needed to understand common confusions users feel when setting up their GoXLR. I conducted a research study with four streamers that the team was working closesly with.</p>
          <p>From the results of the study I was able to map out the journey of what user goes through in order to set up their GoXLR:</p>
            </div>
          <img className="img" src={UJ} style={{borderRadius: "0"}}/>
          <div className="text-paragraph">
            <p>Results from the study also showed that by far the most common pain point for users was setting up their mic and mic levels correctly. Setting up the mic correctly is one of the most important steps in the set up process, as an incorrect set up can cause the GoXLR effects to sound bad.</p>
            <p>With these findings I was able to designed an tutorial flow that followed the same flow form the study with the mic setup as the very first action.</p>
          </div>
          <img className="img" src={ONB1} style={{borderRadius: "0"}}/>
          <div className="text-paragraph">
            <p>Clicking on "Setup" takes you to the "Mic adjustment" tool which  is also accessible from Settings.</p>
          </div>
          <img className="img" src={Mic} style={{borderRadius: "0"}}/>
        </div>
      </div>

      <div id="result">
        <div>
          <h3 style={{padding: "12px 0"}}>Result</h3>
        </div>
        <div className="even-grid">
          <p>GoXLR was launched to the public at Twitchcon 2018. It was immensly successful, and became TC Helicon's most profitable product (generating over $3 million in 2019). The success brought funding for the team to build <Link className="link" to="https://www.tc-helicon.com/product.html?modelCode=0803-AAB" target="_blank">GoXLR MINI</Link>, a smaller version of the device that also works with the same GoXLR app.</p>
          <img className="img" src={Twitchcon}/>
          <div className="text-paragraph">
            <p>As much as the GoXLR was successful as a product, there could have been more initial user testing that could have been done given the resources and time. It was a project with a very short period of time for design, so there were many constraints and limitations that I had to work with.</p>
            <p>For continued growth of GoXLR, we set up a Discord group to engage with GoXLR users and further understand any pain points they would be having with the product. We also added analytics into the app to gather feedback to determine the best default setup. For example, we would look at most used colours so that the default colour profile reflects the most popular preference. We used conversion rates to analyze our measure of success. In addition to the products being sold, our measure for success for the app beca</p>
          </div>
          <div>
          <b>Areas not covered</b>
          <div className="text-paragraph">
            <p>There are many other actions that I took that are not covered in this write up. If you are interested in hearding about them, please don't hesitate to reach out.</p>
            <p>Here are some of my actions that I didn't cover:</p>
            <ul>
              <p>
                <li>
                  GoXLR settings screens
                </li>
                <li>
                  Logo and app icon for Windows
                </li>
                <li>
                <Link className="link" to="https://twitter.com/tchelicongaming" target="_blank">Helicon Gaming</Link> brand design
                </li>
                <li>
                  Twitchcon booth design
                </li>
                <li>
                  Amazon listing asset and content management 
                </li>
              </p>
            </ul>
          </div>
          </div>
        </div>
      </div>

      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={Teamwrk} alt="Teamwrk project" />
            <div>
              <h6>Teamwrk</h6>
              <Link className="explore" to="/teamwrk">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default GoXLRProject