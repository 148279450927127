import React from "react";
import './button.scss';
import DribbbleIcon from '../../assets/icons/Dribbble.svg';

const DribbbleButton = () => {
  return <div onClick={()=>window.open("https://dribbble.com/ravinperera")} 
  
  className="icon-button"><img src={DribbbleIcon} alt="Dribbble Button" style={{ width: "24px", marginRight: "8px"}} /><h5>Dribbble</h5></div>;
};

export default DribbbleButton;